<template>
  <div>
    <b-container v-if="Object.entries(project).length !== 0">
      <b-row>
        <b-col cols="12" md="8"  sm="12" lg="9" xl="9">
          <md-content class="md-elevation-2 md-alignment-center-center context-altice">
            <md-toolbar class="md-transparent" md-elevation="0">
              <h3 class="md-title md-toolbar-section-start" style="flex: 1">Projeto - {{ project.lt }}</h3>
            </md-toolbar>
            <md-list>
              <md-list-item>
                <div class="md-list-item-text">
                  <p><b>Nome Empresa Cliente</b> {{ project.client }}</p>
                  <p><b>Data Adjudicação </b>{{ project.start | dateFormat('DD/MM/YYYY') }}</p>
                  <p><b>Data Objetivo </b>{{ project.end | dateFormat('DD/MM/YYYY') }}</p>
                </div>
              </md-list-item>
              <md-list-item>
                <div class="md-list-item-text">
                  <span><b>Descrição</b></span>
                  <p>{{ project.description }}</p>
                </div>
              </md-list-item>
              <md-list-item>
                <div class="md-list-item-text">
                  <p></p>
                </div>
              </md-list-item>
            </md-list>
          </md-content>
          <md-content class="md-elevation-2 md-alignment-center-center card-altice-detail" style="margin-top: 5px; margin-bottom: 0px">
            <h5 style="padding: 10px">
              Ponto de Situação
            </h5>
          </md-content>
          <md-content class="md-elevation-2 card-altice-detail" style="margin-top: 2px; text-align: left; font-size: 12px !important;" v-for="(item, index) in history" :key="index" :index="index">
            <b-row>
              <b-col cols="10" md="10"  sm="10" lg="10" xl="10">
                <h6 style="padding-top: 20px; padding-bottom: 15px"><b>Data: {{ convertToDate(item.date_status_report) | dateFormat('DD/MM/YYYY') }}</b></h6>
              </b-col>
              <b-col cols="2" md="2"  sm="2" lg="2" xl="2">
                <button @click="cleanTextFunction" style="margin-top: 5px; position: absolute; right: 2px;" class="button-format-clear">
                  <md-tooltip md-direction="bottom" style="margin-top: -35px;">Clean Text</md-tooltip>
                  <md-icon class="button-color">format_clear</md-icon>
                </button>
              </b-col>
            </b-row>
            <span v-if="!cleanText" v-html="item.status_customized"></span>
            <div v-if="cleanText" v-html="item.status_md"></div>
          </md-content>
        </b-col>
        <b-col cols="12" md="4"  sm="12" lg="3" xl="3">
          <div>
            <md-bottom-bar md-sync-route>
              <md-bottom-bar-item @click="callMe" md-label="Contacte-me" md-icon="phone_callback"></md-bottom-bar-item>
              <md-bottom-bar-item @click="sendMessage" md-label="Enviar Mensagem" md-icon="sms"></md-bottom-bar-item>
            </md-bottom-bar>
          </div>
          <md-content class="md-elevation-2 md-alignment-center-center card-altice-detail">
            <contact v-if="contacts[0]" :contacts="contacts[0]"></contact>
            <md-divider></md-divider>
            <p class="title">Contactos</p>
            <md-divider></md-divider>
            <div v-for="(item, index) in contacts" v-bind:key="index">
              <contact v-if="index !== 0" :contacts="item"></contact>
            </div>
          </md-content>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="notFound">
      <card-dialog text="Nenhum resultado" subTitle="Na busca do detalhe do projeto não tivemos retorno!" icon="cloud_off"></card-dialog>
    </b-container>
  </div>
</template>

<script>
import Contact from '@/components/Contact.vue'
import Swal from 'sweetalert2'
import Projects from '@/api/services/Projects.js'
import Sms from '@/api/services/Sms.js'
import Email from '@/api/services/Email.js'
import CardDialog from '@/components/CardDialog.vue'
import Auth from '@/api/services/Auth.js'

export default {
  name: 'ProjectDetail',
  props: ['uuid'],
  components: {
    Contact,
    CardDialog
  },
  data () {
    return {
      contacts: [],
      item: this.$store.state.project ? this.$store.state.project : {},
      value: '',
      valuea: '',
      valueb: '',
      projects: [],
      project: {},
      notFound: false,
      cleanText: false,
      hooperSettings: {
        vertical: true,
        centerMode: true,
        pagination: false
      },
      pdsHistory: '',
      popupActivo: false,
      popupActivo2: false,
      colorx: '#7E7E81',
      jsondata: [],
      history: [],
      heightStyle: 'height: 400px',
      heightStyleSlide: 'height: 350px'
    }
  },
  watch: {
    history: function (val) {
      this.pdsHistory = new Date(val[0].date_status_report)
      val.forEach(this.setJsonData)
    }
  },
  methods: {
    convertToDate (item) {
      return new Date(item)
    },
    setJsonData (historyItem) {
      this.jsondata.push({
        'date_pds': historyItem['date_status_report'],
        'status': historyItem['status_md']
      })
    },
    exportAsExcel () {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'test'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    },
    exportAsCsv () {
      const format = 'csv'
      const exportSelectedOnly = false
      const filename = 'test'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    },
    slidePrev () {
      this.$refs.carousel.slidePrev()
    },
    slideNext () {
      this.$refs.carousel.slideNext()
    },
    updateCarousel (payload) {
      this.myCarouselData = payload.currentSlide
      if (this.history.length > 0) {
        // height
        this.heightStyle = 'height: ' + (this.$refs.slide0[0].clientHeight + 50) + 'px'
        this.heightStyleSlide = 'height: ' + (this.$refs.slide0[0].clientHeight + 1) + 'px'
        this.pdsHistory = new Date(this.history[payload.currentSlide].date_status_report)
      }
    },
    cleanTextFunction () {
      this.cleanText = this.cleanText === false
    },
    callMe () {
      Swal.fire({
        // title: 'Deseja que o nosso gestor de projeto contacte?',
        text: 'Deseja que o nosso gestor de projeto o contacte?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0084D6',
        cancelButtonColor: '#333333',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          Sms.sendSms(this.item.lt).then((response) => {
            Swal.fire(
              'Enviado!',
              'Brevemente o gestor de projeto entrará em contacto.',
              'success'
            )
          }).catch(() => {
            Swal.fire(
              'Atenção!',
              'Não foi possível contacta-lo no momento, tente novamente!',
              'error'
            )
            this.$loading(false)
          })
        }
      })
    },
    sendMessage () {
      Swal.mixin({
        confirmButtonText: 'Próximo',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        progressSteps: ['1', '2'],
        confirmButtonColor: '#0084D6',
        cancelButtonColor: '#333333',
        reverseButtons: true
      }).queue([
        {
          // title: 'Assunto',
          input: 'text',
          text: 'Assunto'
        },
        {
          input: 'textarea',
          text: 'Mensagem',
          confirmButtonText: 'Enviar'
        }
      ]).then((result) => {
        if (result.value) {
          if (result.value[0] && result.value[1]) {
            let data = {
              'subject': result.value[0],
              'message': result.value[1]
            }
            Email.sendEmail(this.item.lt, data).then((response) => {
              Swal.fire(
                'Enviado!',
                'Brevemente o gestor de projeto entrará em contacto.',
                'success'
              )
            }).catch(() => {
              Swal.fire(
                'Atenção!',
                'Não foi possível contacta-lo no momento, tente novamente!',
                'error'
              )
              this.$loading(false)
            })
          } else {
            Swal.fire(
              'Atenção!',
              'Todos os campos são obrigatórios.',
              'error'
            )
          }
        }
      })
    },
    getProjects () {
      this.contacts = []
      Projects.getLts(this.$route.params.uuid).then((response) => {
        this.projects = response.data.results.map(object => {
          let item = {}
          item = {
            'lt': object.lt,
            'description': this.item.description,
            'start': new Date(this.item.start),
            'end': new Date(this.item.end),
            'client': object.customer_name,
            'status': object.status_customized,
            'status_md': object.status_md,
            'statusDate': object.planned_date,
            'nextStep': object.last_status_report_next_steps,
            'rate': 5
          }
          this.contacts.push({
            title: 'Gestor de Projeto',
            name: object.gp_short_name,
            phone: object.gp_mobile,
            email: object.gp_email,
            src: 'https://projetos.clientesb2b.telecom.pt/avatar-2.png'
          })
          this.contacts.push({
            title: 'Diretor',
            name: object.dir_short_name,
            phone: object.dir_mobile,
            email: object.dir_email,
            src: 'https://projetos.clientesb2b.telecom.pt/avatar.png'
          })
          this.contacts.push({
            title: 'Sub Diretor',
            name: object.sub_dir_short_name,
            phone: object.sub_dir_email,
            email: object.sub_dir_mobile,
            src: 'https://projetos.clientesb2b.telecom.pt/avatar-2.png'
          })
          this.contacts.push({
            title: 'Resp. de Coordenação',
            name: object.resp_coord_short_name,
            phone: object.resp_coord_email,
            email: object.resp_coord_mobile,
            src: 'https://projetos.clientesb2b.telecom.pt/avatar-2.png'
          })
          this.contacts.push({
            title: 'Coordenador',
            name: object.coord_short_name,
            phone: object.coord_email,
            email: object.coord_mobile,
            src: 'https://projetos.clientesb2b.telecom.pt/avatar-2.png'
          })
          return item
        })
        this.project = this.projects[0]
        this.$loading(false)
      }).catch(() => {
        this.$loading(false)
      })
    },
    getHistory () {
      Projects.getLtsHistory(this.$route.params.uuid).then((response) => {
        this.history = response.data.results
      }).catch(() => {
      })
    }
  },
  created () {
    this.$loading(true)
    if (this.$store.state.internal) {
      Auth.logout()
    }
    // if (Object.entries(this.$store.state.project).length === 0) {
    Projects.getProjects().then((response) => {
      this.item = response.data.results.filter((item) => {
        return item.lt === this.$route.params.uuid
      })
      this.item = this.item.map(object => {
        let item = {}
        item = {
          'lt': object.lt,
          'description': object.opportunity_description,
          'start': new Date(object.date_award),
          'end': new Date(object.planned_date),
          'manager': object.project_manager_name,
          'percent': object.execution !== null ? object.execution : 0,
          'selected': false
        }
        return item
      })
      this.item = this.item[0]
      this.getProjects()
      this.getHistory()
    }).catch(() => {
      this.notFound = true
      this.$loading(false)
    })
    // } else {
    //   this.getProjects()
    // }
  }
}
</script>

<style lang="scss">
.hooper-liveregion {
  color: #ffffff;
}
.slide-pds {
  min-width: 90%;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 40px;
  border-top-color: rgba(0,0,0, .1);
  border-top-style: solid;
  border-top-width: 1px;
  // border-right-color: transparent;
  border-right-color: rgba(0,0,0, .1);
  border-right-style: solid;
  border-right-width: 1px;
  // border-bottom-color: transparent;
  border-bottom-color: rgba(0,0,0, .1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // border-left-color: transparent;
  border-left-color: rgba(0,0,0, .1);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.pds-title {
  top: 1px;
  margin-left: auto;
  position: absolute;
  left: 20px;
  width: 400px;
  height: 40px;
  z-index: -1;
  font-size: 14px;
}
.arrow-up {
  top: 5px;
  position: absolute;
  right: 17px;
  width: 40px;
  height: 80px;
  z-index: 9999;
}
.pds-log {
  top: 60px;
  position: absolute;
  right: 20px;
  width: 40px;
  z-index: 9999;
}
.pds-excel {
  top: 90px;
  position: absolute;
  right: 20px;
  width: 40px;
  z-index: 9999;
}
.arrow-right {
  position: absolute;
  bottom: 5px;
  right: 17px;
  width: 40px;
  z-index: 9999;
}
.arrow-left {
  position: absolute;
  bottom: 5px;
  left: 17px;
  width: 40px;
  z-index: 9999;
}
.button-toggle {
  position: absolute;
  bottom: 75px;
  right: 17px;
  width: 40px;
  z-index: 9999;
}
.button-format-clear {
  background-color: transparent; /* Green */
  background-repeat: no-repeat;
  border: none;
  color: white;
  padding: 1px 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px 0px;
  transition-duration: 0.1s;
  outline: none;
  cursor: pointer;

}
.button-format-clear:hover {
  background-color: #0084D6;
  color: #ffffff;
  .button-color {
    background-color: none;
    color: #ffffff;
  }
}
.button-color {
  background-color: none;
  color: #000000;
  transition-duration: 0.1s;
}
.button-toggle-bar {
  margin: -10px !important;
  padding: 0px;
}
.md-bottom-bar-clean {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0), 0 8px 10px 1px rgba(0,0,0,0), 0 3px 14px 2px rgba(0,0,0,0) !important;
  width: 100%;
  transition: background-color .5s cubic-bezier(.4,0,.2,1);
}
.context-altice {
  min-height: 100px !important;
}

.status-html-history {
  min-width: 90%;
  padding-left: 10px;
  padding-right: 40px;
  // padding-top: 10px;
  // padding-bottom: 40px;
  border-top-color: rgba(0,0,0, .1);
  border-top-style: solid;
  border-top-width: 1px;
  // border-right-color: transparent;
  border-right-color: rgba(0,0,0, .1);
  border-right-style: solid;
  border-right-width: 1px;
  // border-bottom-color: transparent;
  border-bottom-color: rgba(0,0,0, .1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // border-left-color: transparent;
  border-left-color: rgba(0,0,0, .1);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.status-html {
  min-width: 90%;
  min-height: 150px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 40px;
  border-top-color: rgba(0,0,0, .1);
  border-top-style: solid;
  border-top-width: 1px;
  // border-right-color: transparent;
  border-right-color: rgba(0,0,0, .1);
  border-right-style: solid;
  border-right-width: 1px;
  // border-bottom-color: transparent;
  border-bottom-color: rgba(0,0,0, .1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // border-left-color: transparent;
  border-left-color: rgba(0,0,0, .1);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.status-html-reset {
  * {
    all: unset;
  }
}
span {
  overflow: visible;
}
.title {
  font-size: 1rem;
  text-align: left;
  // padding-top: 15px;
  // padding-right: 15px;
  padding-left: 15px;
  font-weight: 900;
  margin-bottom: 0;
}
.card-altice-detail {
  margin: auto auto;
  max-width: 1000px;
  // min-height: 45vh;
  padding-left: 15px;
}
.md-title {
  font-size:20px;
  font-weight:700!important;
  text-transform:none;
}
.info-content {
  text-align: initial
}
.rate {
  display: flex;
  width: 152px;
}

.Rate__star {
  padding: 7px 0px!important;
  text-align: left !important;
  margin: -6px 0px 0px 0px;
  svg{
    margin:0!important;
  }
}

.Rate__star.filled {
  color: #0084D6 !important;
}

.Rate__star.hover {
  color: #0084D6 !important;
}

.md-has-textarea {
  margin-top:20px !important;
  textarea{
    font-style:italic!important;
  }
}

.md-has-textarea:after {
  border-color:#ebebeb!important;
}

.item-list-altice {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  overflow: visible;
}
.item-list-altice-two {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  overflow: visible;
}
.md-list-item-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  line-height: 24px;
  white-space: normal;
  font-size: 12px!important;
    p{
      font-size: 12px!important;
      line-height: 24px;
    }
}

.item-list-altice-two{
  line-height: 20px;
}

.md-field.md-focused .md-input, .md-field.md-focused .md-textarea, .md-field.md-has-value .md-input, .md-field.md-has-value .md-textarea {
  font-size: 12px !important;
}

.md-bottom-bar-item {
  height:80px !important;
  border-bottom:1px solid #ebebeb;
  .md-bottom-bar-icon {
    color:black !important;
  }
  span {
    color:black !important;
  }
}

.md-bottom-bar-clean {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0), 0 8px 10px 1px rgba(0,0,0,0), 0 3px 14px 2px rgba(0,0,0,0) !important;
  width: 100%;
  transition: background-color .5s cubic-bezier(.4,0,.2,1);
}

.md-bottom-bar-item:first-child {
  border-right: 1px solid #ebebeb;
}

.md-bottom-bar-item:hover {
  background-color: #0084D6 !important;
  color:#fff !important;
  .md-bottom-bar-icon {
    color:#fff !important;
  }
  span{
    color:#fff !important;
  }
}

.contact-title {
  text-transform: uppercase;
  font-size:14px;
}

.vue-avatar--wrapper {
  // border: 2px solid #0084D6;
  background-color: #0084D6;
  width: 52px;
  height: 52px;
}

.md-divider {
    background-color:#ebebeb !important;
}

.swal2-error {
  border-color:#333 !important;
  color:#333 !important;
}

.swal2-x-mark-line-left,.swal2-x-mark-line-right{
  background-color:#333 !important;
}

.swal2-success-ring{
  border-color:#0084D6!important;
}

.sswal2-success-line-long,.swal2-success-line-tip{
  background-color:#0084D6!important;
}

span {
  table {
    max-width: 80% !important;
  }
}
</style>
